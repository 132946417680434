.section-footer {
  background-color: #484646;
  padding-bottom: 0px;
  padding-top: 2%;
  margin-top: 0;
}
.img-footerlogo {
  height: 50px;
  background: white;
  padding: 4px;
}
.footer-flx {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 20px;
  margin-top: 10px;
}
.call-icn {
  font-size: 20px;
  color: white;
}
.call-txt {
  font-size: 17px;
  color: white;
}
.call-txthotel {
  font-size: 17px;
  color: white;
  margin-left: 1px;
}
.footer_heading {
  color: #fff;

  font-size: 23px;
  margin-top: 20px;
}
.footer_content1 {
  color: #d3d3d3;

  font-size: 15px;
  margin-bottom: 10px;
}
.footer_content {
  margin-top: 20px;
  margin-bottom: 10px;
  color: #d3d3d3;
}
.footer1-last {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 20px;
  padding: 20px;
  background-color: rgb(59, 58, 58);
  color: white;
}
.copyright {
  font-size: 15px;
  margin-bottom: 3px;
  margin-top: 8px;
}
.foot1-flx1 {
  width: 6%;
}
.last-logo {
  height: 29px;
}
.container2 {
  position: relative;

  margin: 22px auto;
}
.with-error {
  margin-top: 30px;
}
.input-field2 {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
  outline: none;
}
.submit-button2 {
  position: absolute;
  top: 41.5%;
  right: 7px;
  transform: translateY(-50%);
  background-color: #208fe1;
  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 5px 16px;
  font-size: 14px;
  cursor: pointer;
}
.submit-button2:hover {
  background-color: #0056b3;
}
.cont-txt {
  color: #d3d3d3;
}
.linkcolor {
  color: #d3d3d3;
  text-decoration: none;
}
.linkcolor:hover {
  color: #6858df;
  text-decoration: none;
}
.copyrightlink {
  text-decoration: none;
  color: #337ab7;
}
.foot-flx1 {
  margin-top: -2px;
}
@media (max-width: 800px) {
  .section-footer {
    background-color: #484646;
    padding-bottom: 0px;
    padding-top: 8%;
    margin-top: 0%;
  }
  .footer_heading,
  .footer_content,
  .footer_content1 {
    margin-left: 3px;
  }
  .foot1-flx1 {
    width: 0%;
  }
  .foot1-flx2 {
    width: 100%;
  }
  .footer1-last {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 0px;
    padding: 20px;
    background-color: rgb(59, 58, 58);
    color: white;
  }
}
