.pricing-section
{
    background-color: black;
    margin-top:50px;
    padding-top: 40px;
    padding-bottom: 50px;
    color:white;
}
.pricing-line {
    height: 2px;
    background-color: rgb(255, 255, 255);
    color: rgb(255, 255, 255);
    border: none;
    width: 50px;
    margin-top: 8px;
}
.price-padd
{
    padding-left:70px;
    padding-right:70px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 40px;
    border:1px solid red;
}
.price-txt
{
    font-size: 25px;
    margin-bottom: 4px;
    font-weight: bold;
}
.price-txt1
{
    font-size: 35px;
    color: black;
    margin-bottom:1px;
    font-weight: bold;

}
.price-card1
{
    background-color: #208FE1;
    color: white;
    padding: 15px;
    border-radius: 8px;
    margin-left: 30px;
    margin-right: 30px;
}
.price-txt2
{
    color:black;
    font-weight: bold;
}
.pricing-flx
{
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap:10px;
}
.pricing-bot
{
    height:50px;
    color:black;
    background-color: white;
    border:none;
    border-radius: 7px;
    width:60%;
    margin-top: 3px;
    margin-bottom: 6px;
}
.pricing1-bot
{
    height:50px;
    color:rgb(255, 255, 255);
    background-color: #208FE1;
    border:none;
    border-radius: 7px;
    width:60%;
    margin-top: 10px;
}
.pricing-mnth
{
    font-size: 80px;
    font-weight: 700;
    line-height: 90px;
    margin-bottom: 30px;

}
.deskbox-feature {
    font-size: 35px;
    font-weight: 600;
    margin-bottom: 0;
}
@media(max-width:900px){

    .price-card1
{
    background-color: #208FE1;
    color: white;
    padding: 20px;
    border-radius: 8px;
    margin-left: 2px;
    margin-right: 2px;
    margin-bottom: 30px;
}
.price-padd {
    padding-left: 5px;
    padding-right: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
}
.pricing-flx {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 10px;
}
.pricing-mnth
{
    font-size: 30px;
    font-weight: 700;
    line-height: 43px;
    margin-bottom: 30px;

}
}