.stay-feature {
    font-size: 35px;
    font-weight: 600;
    margin-bottom: 0;
}
.stay-line {
    background-color: #000;
    border: none;
    color: #000;
    height: 2px;
    margin-top: 8px;
    width: 80px;
}
.stay-txt2 {
    color: #484848;
    font-size: 35px;
    font-weight: 700;
    letter-spacing: 1px;
}
.stay-descri {
    color: #484848;
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 1vh;
}
.desk-top-2 {
    margin-left: 15px;
    margin-top: 4vh;
}
.stay-feature1-flx {
    align-items: flex-start;
    display: flex;
    gap: 20px;
    justify-content: flex-start;
    margin-top: 8px;
    width: 100%;
}
/* .flx3 {
    width: 91vh;
} */
.flx2 {
    padding-top: 8px;
}
.feature-icn {
    background-color: #173e87;
    border-radius: 5%;
    color: rgb(255, 255, 255);
    font-size: 16px;
    padding: 10px;
    border-radius: 2px;
}
.feature-img2 {
    padding-right: 40px;
}
.feature-txt1 {
    color: #242424;
    font-size: 17px;
    margin-bottom: 3px;
    font-weight: 700;
    letter-spacing: 1px;
}
.feature-top02 {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding-left: 5%;
    padding-right: 5%;
    padding-top: 3%;
    padding-bottom: 3%;
}
.feature1-txt2 {
    color: #1e1e1e;
    font-size: 17px;
    font-weight: 600;
    letter-spacing: 1px;
    margin-left: 15px;
}
@media(max-width:880px)
{
    .feature-img2 {
        padding-right: 0px;
        margin-top: 10px;
        margin-bottom: 10px;
    }
    .stay-feature {
        font-size: 28px;
        font-weight: 600;
        margin-bottom: 0;
    }
    .stay-txt2 {
        color: #484848;
        font-size: 26px;
        font-weight: 700;
        letter-spacing: 1px;
        margin-top: 20px;
    }
    .feature-top02 {
        align-items: center;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        padding-left: 3%;
        padding-right: 3%;
        margin-top: 4%;
    }
    .feature-icn {
        background-color: #173e87;
        border-radius: 5%;
        color: rgb(255, 255, 255);
        font-size: 11px;
        padding: 7px;
        border-radius: 2px;
    }
    .feature1-txt2 {
        color: #484848;
        font-size: 15.5px;
        font-weight: 600;
        letter-spacing: 1px;
        /* margin-top: 1vh; */
    }
    .flx2 {
        padding-top: 3px;
    }
}