#Comming_Soon .coming-soon-section-soon {
    background: url('../../../../../assets/back.png') no-repeat center center/cover;
    height: 100vh;
    position: relative;
    background-position: center;
}

#Comming_Soon .shine-soon{
 background: rgba(0, 0, 0, 0.4);
 width: 100%;
 height: 100%;
}

/* #Comming_Soon .overlay { */
#Comming_Soon .over-soon {
    /* background: rgba(0, 0, 0, 0.3);  */
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color:white;
}

.display-soon{
    display: flex !important;
    width: 100%;
    /* background-color: rosybrown; */
    flex-direction: row;
    align-items: center;
}
.display .one_soon{
    /* background-color: green; */
    width: 50%;
}


#Comming_Soon .heading-soon {
    
    /* background-color: red; */
    font-size: 7rem;
    text-align: center;
    font-family: math;
    letter-spacing: 10px;
}

#Comming_Soon .paragraph_coming-soon{
    font-size: 1.3rem;
    padding-left: 40px;
}
#Comming_Soon .firstPara-soon{
    font-size: 30px;
    color:white;
    font-weight: bold;
}

#Comming_Soon .highlight_comming-soon{
    border-right: 1px solid white;
}

@media (max-width: 768px) {
    #Comming_Soon .highlight_comming-soon {
        border-right: none;
    }
}

@media (max-width:992px) {
    #Comming_Soon .heading-soon {
            font-size: 5rem;
            text-align: center;
        }
}
@media (max-width:490px) {
    #Comming_Soon .heading-soon {
        font-size: 4rem;
        text-align: center;
    }
}

.navbar .navbar-brand,
.navbar .nav-link {
    color: white !important;
}