.modal {
  position: fixed;
  opacity: 0;
  top: 50%;
  left: 50%;
  z-index: 1000;
  transform: translate(-50%, -100%);
  min-width: 100px;
  min-height: 100px;
  max-height: 90vh;
  max-width: 90vw;
  box-shadow: 1px 1px 5px 0 gray;
  border-radius: 5px;
  background: #fff;
  transition: all 0.3s ease-in-out;
  overflow: hidden;
}
.modal > h2 {
  font-size: 18px;
  padding: 10px 20px;
  /* box-shadow: 1px 2px 5px lightgray; */
  height: 60px;
  color: #666;
  display: flex;
  align-items: center;
}
.modal > div,
.modal > form {
  overflow-x: hidden;
  overflow-y: auto;
  max-height: calc(90vh - 60px);
}
/* .modal > div::-webkit-scrollbar {
  width: 5px;
}
.modal > div::-webkit-scrollbar-thumb {
  background: var(--blue-dark);
  border-radius: 5px;
} */
.closeButton {
  position: fixed;
  top: -60px;
  right: -60px;
  width: 120px;
  height: 120px;
  z-index: 1001;
  border-radius: 50%;
  transition: all 0.3s;
  background: rgb(246, 80, 80);
  border: 0;
}
.closeButton:hover {
  background: red;
}
.closeButton:hover > div div {
  background: #fff;
}
.closeButton > div div {
  width: 20%;
  height: 3px;
  background: #fff;
  border-radius: 2px;
  position: absolute;
  top: 70%;
  left: 30%;
  transform: translate(-50%, -50%) rotate(45deg);
  transition: all 0.3s;
}
.closeButton > div div:last-child {
  transform: translate(-50%, -50%) rotate(-45deg);
}
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.3);
  opacity: 0;
  transition: all 0.3s ease-in-out;
}

@media screen and (min-width: 768px) {
  .modal {
    min-width: 600px;
  }
}

@media screen and (max-width: 640px) {
  .modal {
    width: 90vw;
  }
  .modal > h2 {
    font-size: 14px;
  }
}
