@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  min-width: 0;
}
:root {
  --primary-color: #5db64c !important;
  --primaryShade-bgColor: #5db64c45 !important;
  --primaryShade-Color: rgb(46, 126, 30) !important;
}
html,
body {
  overflow-x: hidden;
}

.admin-panel-nav-link {
  position: relative;
}
.admin-panel-nav-link::before {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 5px;
  content: "";
  background: #fff;
  border-radius: 3px;
  transform: scaleY(0);
  transition: all 0.3s;
}
.active-admin-panel-nav-link::before {
  transform: scaleY(1);
}

.product-modal-section-btn {
  position: relative;
  padding: 10px 10px 5px;
}
.product-modal-section-btn::after {
  position: absolute;
  bottom: 0;
  left: 0;
  content: "";
  width: 100%;
  height: 2px;
  border-radius: 2px;
  background: var(--primary-color);
  transition: all 0.3s;
  transform: scaleX(0);
}

.active-product-modal-section-btn::after {
  transform: scaleX(1) !important;
}

.ck-editor__editable {
  min-height: 100px !important;
  max-height: 300px;
}
.ck-dropdown__arrow {
  z-index: 0 !important;
}
