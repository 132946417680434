.modal-content {
    max-height: 90vh;
    display: flex;
    flex-direction: column;
}

.modal-header,
.modal-footer {
    flex-shrink: 0;
}

.modal-body {
    flex-grow: 1;
    overflow-y: auto;
}