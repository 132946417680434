@import url("https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200..1000;1,200..1000&display=swap");
/* @import "primeicons/primeicons.css";
@import url("https://unpkg.com/primeflex@latest/primeflex.css"); */ 

body {
  font-family: "Nunito", sans-serif !important;
  letter-spacing: 1px;
}


/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}