.room-booking-section {
  background-color: rgba(235, 233, 233, 0.493);
  margin: 10px 0;
  padding: 15px 0;
}

.booking_add_wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* background-color: rgba(5, 5, 53, 0.116); */
  padding: 15px;
}

.booking_add {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}

.time-slot-item {
  width: auto;
  cursor: pointer;
}

.booking_add .selected {
  background-color: #35c155;
  /* color: #fff; */
  border-color: #28a745;
}

.booking_add .notSelected {
  /* background-color: red; */
  /* color: #fff; */
  border-color: red;
}

.booking_add .room_name {
  margin-bottom: 0;
  padding: 5px 10px;
}

.booking_add .card {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.421);
  border: 1px solid rgba(0, 0, 0, 0.216);
  border-radius: 0.25rem;
  transition: all 0.3s ease;
}
