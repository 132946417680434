.multi-check-div {
  font-weight: 600;
}

.multi-check-box {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  flex-wrap: wrap;
  margin-top: 4px;
}

.multi-check-box .label {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px;
  border-radius: 6px;
  cursor: pointer;
  font-weight: 600;
  white-space: nowrap;
}

.multi-check-box .label.selected {
  background-color: #0b5dd74d;
  border: 2px solid #0b5ed7;
  color: #0b5ed7;
}

.multi-check-box .label.normal {
  border: 2px solid gray;
  color: gray;
}

.multi-check-box input {
  display: none;
}

.multi-check-box .label span {
  display: flex;
  align-items: center;
  justify-content: center;
}
