.hotel_table .edit-btn {
  height: 30px;
  width: 30px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px;
}

th td {
  font-size: 14px;
}

.left-clickbtn {
  background-color: white;
  height: 30px;
  width: 30px;
  border: 1px solid blue;
  display: flex;
  justify-content: center;
  align-items: center;
}
.sp1 {
  width: 25%;
  padding-left: 2px;
}
.sp2 {
  width: 17%;
}
.sp3 {
  width: 17%;
}
.v1 {
  width: 20%;
}
.v2 {
  width: 20%;
}
.v3 {
  width: 20%;
}

.mypage1 {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  margin-top: 8px;
}

.page-flx1 {
  flex-wrap: wrap;
  gap: 15px;
}

.dd > .hotel_table > .tablebord {
  padding: 0px;
  box-shadow: none;
  background-color: transparent;
}

.sss {
  display: inline-block;
}

/* th {
  white-space: nowrap;
  text-align: center;
  text-transform: capitalize;
} */

.text-center {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.action a {
  color: white;
}

.action {
  width: 150px !important;
}

.mknk-pagignation {
  margin-top: 14px;
}

/* td:not(:last-child) {
    width: 300px;
    white-space: nowrap;
}

td:last-child {
    width: 100px;
    white-space: nowrap;
    display: flex;
    justify-content: space-around;
    align-items: center;
} */

.table-container {
  width: 100%;
  overflow-x: auto;
}

/* table {
  width: 100%;
  border-collapse: collapse;
}

th,
td {
  padding: 8px;
  border: 1px solid #ddd;
  text-align: left;
} */

.center {
  text-align: center;
}

.underline_none {
  text-decoration: none;
}

.relodbtn1 {
  height: 40px;
  width: 60px;
}

.relodbtnsub {
  height: 40px;
  width: 50px;
}

.myload {
  position: absolute;
  width: 80%;
  height: 70vh;
  display: flex;
  justify-content: center;
  align-items: center;
  /* background-color: red; */
}

.dropon {
  height: 37px;
  width: 100%;
  border-radius: 5px;
  border: 1px solid rgb(212, 212, 212);
  outline: none;
}

.hotel-active {
  /* background-color: green; */
  padding: 6px;
  text-align: center;
  border-radius: 6px;
  font-weight: bold;
  border: 1px solid green;
  color: green;
  font-size: 13px;
  margin-bottom: 0px;
}

.hotel-inactive {
  text-align: center;
  font-size: 13px;
  border-radius: 6px;
  padding: 6px;
  /* background-color: red; */
  border: 1px solid red;
  color: red;
  margin-bottom: 0px;
}

.rupee {
  color: white;
}

#spdrop {
  width: 200px;
}

.bluebutton {
  height: 39px;
  width: 39px;
  background-color: #0b9de8;
  color: white;
  border-radius: 8px;
}

.hotel_flx1 {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  background-color: transparent;
}

.hotel_filter {
  display: flex;
  background-color: transparent;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
}

.scroll_table-hotel {
  max-height: 40vh;
  overflow: scroll;
  scrollbar-width: none;
  /* box-shadow: rgba(17, 17, 26, 0.1) 0px 0px 16px; */
  padding: 10px;
  position: relative;
}
.scroll_table {
  max-height: 51vh;
  overflow: scroll;
  scrollbar-width: none;
  /* box-shadow: rgba(17, 17, 26, 0.1) 0px 0px 16px; */
  position: relative;
  border-top: 1px solid white;
  border: none;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  border: 1px solid #e8e8e8;
  /* border-left: none;
    border-right: none; */
}
.ab {
  position: relative;
}
.mknk label {
  font-weight: normal;
}
.ab1 {
  position: absolute;
  right: 15.5px;
  top: 10px;
  font-size: 18px;
  font-weight: normal;
  color: #343a40;
}

.hotel_table .scroll_table table tbody tr td th {
  /* border: 0px solid #ddd; */
  border-bottom: 0px solid #ddd;
  padding: 8px;
}

.hotel_table td:first-child,
.hotel_table th:first-child {
  width: 100px !important;
}

.hotel_table thead {
  position: fixed;
}

.hotel_table .scroll_table table thead {
  position: sticky;
  top: 0;
  z-index: 2;
  border: none;
}

.hotel_table th:first-child {
  width: 103px !important;
  border-top: none;

  background-color: rgb(232, 234, 237);
}
/* 
th:not(:nth-child(1)) {
  border-top: none;
  background-color: rgb(232, 234, 237);
} */
.hotel_table .fixx1 {
  position: fixed;
}

.hotel_table .nodatafound1 {
  text-align: center;
}
.hotel_table thead {
  border: 1px solid black;
  position: fixed;
  border: none !important;
}
.my_spin {
  position: absolute;
  top: 2.2px;
  right: 6px;
}
.new1 .featured-flx {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 60px;
  margin-bottom: 10px;
  margin-top: 10px;
}
.scroll_divstop {
  overflow: hidden;
}

@media (max-width: 1400px) {
  .scroll_table-hotel {
    max-height: 48vh;
    overflow: scroll;
    scrollbar-width: none;
    /* box-shadow: rgba(17, 17, 26, 0.1) 0px 0px 16px; */
    padding: 10px;
    position: relative;
  }
  .v1 {
    width: 25%;
  }
  .v2 {
    width: 25%;
  }
  .v3 {
    width: 25%;
  }
}

@media (max-width: 900px) {
  .sp > .search-inp {
    width: 100% !important;
    border-radius: 8px !important;
    margin-top: 20px;
  }
  .v1 {
    width: 100%;
  }
  .v2 {
    width: 100%;
  }
  .v3 {
    width: 100%;
  }
}
