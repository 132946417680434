.hotel-my-top
{
    margin-top:3%;
}
.hotel-stay-feature {
    font-size: 35px;
    font-weight: 600;
    margin-bottom: 0;
}
.hotel-stay-line {
    background-color: #000;
    border: none;
    color: #000;
    height: 2px;
    margin-top: 8px;
    width: 80px;
}
.hotel-stay-txt2 {
    color: #010101;
    font-size: 35px;
    font-weight: 700;
    letter-spacing: 1px;
    margin-bottom: 5px;
}
.hotel-stay-descri {
    color: #484848;
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 1vh;
}
.hotel-desk-top-2 {
    margin-left: 0px;
    margin-top: 2vh;
}
.hotel-stay-feature1-flx {
    align-items: flex-start;
    
    display: flex;
    gap: 20px;
    justify-content: flex-start;
    margin-top: 8px;
    width: 100%;
}
.hotel-flx3 {
    width: 91vh;
}
.hotel-flx2 {
    padding-top: 1px;
}
.hotel-feature-icn {
    /* background-color: #208fe1; */
    border-radius: 50%;
    color: black;
    font-size: 16px;
    padding: 0px;
}
.hotel-feature-img2 {
    padding-right: 40px;
}
.hotel-feature-txt1 {
    color: #ffffff;
    font-size: 17px;
    margin-bottom: 3px;
    font-weight: 700;
    letter-spacing: 1px;
}
.hotel-feature-top02 {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding-left: 5%;
    padding-right: 5%;
    margin-top: 2%;
    box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px;
    padding:30px;
    position: relative;
}
.hotel-feature1-txt2 {
    color: #ffffff;
    font-size: 17px;
    font-weight: 600;
    letter-spacing: 1px;
    /* margin-top: 1vh; */
    font-size: 20px;
}
.hotel-price{
    color: #080707;
    font-size: 35px;
    font-weight: 700;
    letter-spacing: 1px;
    margin-bottom: 0px;
}

.stay-pricing1 {
    height: 50px;
    color: rgb(255, 255, 255);
    background-color: #173e87;
    border: 1px solid white;
    border-radius: 7px;
    padding-left: 30px;
    padding-right: 30px;
    margin-top: 4%;
}
.pricee-img2
{
position: absolute;
bottom: -36px;
right: -10px;
height:105px;
margin-bottom: 15px;
border-radius: 10%;
/* background-color: white; */

}
.pricee-img20
{
position: absolute;
bottom: 20px;
right: 10px;
height:105px;
margin-bottom: 15px;
border-radius: 10%;
/* background-color: white; */

}
.box1
{
    /* border: 1px solid #dedede; */
    background-color: #173e87;
    color:white;
    padding: 60px;
    border-radius: 7px;
    position: relative;
}
.price-txt2 {
    color: #3d3d3d;
    font-weight: 500;
    margin-bottom: 3px;
}
.monthprice-txt2 {
    color: #000;
    font-weight: 700;
    font-size: 22px;
    margin-bottom: 3px;
}
.monthcard
{
    /* box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px; */
    padding: 15px 15px 15px 20px;
    margin-bottom: 20px;
    box-shadow: rgba(67, 71, 85, 0.27) 0px 0px 0.25em, rgba(90, 125, 188, 0.05) 0px 0.25em 1em;
    border-radius: 7px;
    border-bottom: 5px solid #173e87;
}
@media (max-width:1400px)
{
    .box1
    {
        /* border: 1px solid #dedede; */
        background-color: #173e87;
        color:white;
        padding: 40px;
        border-radius: 7px;
        position: relative;
    }
}
@media (max-width:800px){
    .hotel-feature-top02
    {
        padding:10px;
        padding-top:30px;
        gap:20px;
        box-shadow:none;
    }
    .box1
    {

        padding: 20px;
    }
    .hotel-feature1-txt2 {
        color: #ffffff;
        font-size: 17px !important;
        font-weight: 600;
        letter-spacing: 1px;
        /* margin-top: 1vh; */
        font-size: 20px;
    }
    .price-txt3
    {
        font-size: 15px;
    }
}