html {
  font-size: 100%;
  box-sizing: inherit;
  height: -webkit-fill-available;
  scroll-snap-type: y mandatory;
  overflow-y: scroll;
  scroll-behavior: smooth;
}

body {

  font-size: 1rem;
  letter-spacing: 2px;
  font-weight: 400;
  line-height: 1.5;
  scroll-behavior: smooth;
  

}

a,
button {
  cursor: pointer;
  border: none;
  outline: none;
  user-select: none;
  background: none;
  box-shadow: none;
}

a:hover {
  text-decoration: none;
}

.logo-txt {
  color: #ae8c53;
  font-weight: 400;
  font-size: 80px;
  margin-bottom: 2px;

}

.kdp-line {

  height: 2px;
  background: #ae8c53;
  color: #ae8c53;
  border: none;
  margin: 0px;

}


.navbar,
.menu {
  margin: 0;
}

.logo {
  height: 35px;
}

.navbar {
  display: flex;
  align-items: center;
  gap: 0 25px;
}

.header {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #ffffff;
  z-index: 10 !important;
  margin: 0 auto;
  box-shadow: var(--shadow-medium);
  padding: 0;
  padding-bottom: 10px;
  padding-top: 10px;
  /* box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .13), 0 1px 1px 0 rgba(0, 0, 0, .11); */
}

.wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 2rem;
  row-gap: 4rem;
  width: 100%;
  /*height: 70px;*/
  margin: 0 auto;
  padding: 0 20px;
  background-color: transparent;

}

.contact-bt {
  background: #173e87;
  display: inline-block;
  padding: 8px;
  color: white !important;
  padding-left: 17px;
  padding-right: 17px;
  border-radius: 5px;
  border: none;
  height: 40px;

}

@media screen and (max-width: 992px) {

  .navbar {
    position: fixed;
    top: 0;
    left: -100%;
    width: 75%;
    height: 100%;
    z-index: 10;
    opacity: 0;
    overflow-y: visible;
    visibility: hidden;
    box-shadow: var(--shadow-medium);
    background-color: var(--color-white-100);
    transition: all 0.5s ease;
  }

  .menu-link {
    color: white !important;
  }

  .contact-bt {
    background-color: transparent;
  }

  .navbar.active {
    left: 0rem;
    opacity: 1;
    visibility: visible;
    background: black;
  }
  .header {
    position: absolute;
    top: 8px;
    left: 0;
    width: 100%;
    background-color: #ffffff;
    z-index: 10 !important;
    margin: 0 auto;
    box-shadow: var(--shadow-medium);
    padding: 0;
    padding-bottom: 10px;
    padding-top: 10px;
    /* box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .13), 0 1px 1px 0 rgba(0, 0, 0, .11); */
}

}

.menu-item {
  position: relative;
  display: inline-block;
  margin-left: 0.5rem;
  padding: 8px;

  padding-left: 17px;
  padding-right: 17px;
  border-radius: 31px;
}

/* .menu-item {*/
/*	 position: relative;*/
/*	 display: inline-block;*/
/*	 margin-left: 1.5rem;*/
/*}*/
.menu-link {
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 0.25rem;
  font-family: inherit;
  font-size: 16px;
  font-weight: 500;
  line-height: inherit;
  cursor: pointer;
  text-decoration: none;
  text-transform: capitalize;
  color: var(--color-black-500);
  transition: all 0.3s ease-in-out;
  color: rgb(22, 22, 22);
}

.menu-link>i.bx {
  font-size: 1.35rem;
  line-height: 1.5;
  color: inherit;
}

.menu-link:hover {
  outline: none;
  color: #163e87;
  text-decoration: none;
}

.contact-btn {
  border: 1px solid #ae8c53;
  border-radius: 8px;
  background-color: transparent;
  padding: 8px 25px;
  color: #ae8c53;
  transition: all 250ms ease-in-out;
}

.contact-btn:hover {
  background-color: #ae8c53;
  color: #111;
}

@media only screen and (min-width: 993px) {
  .menu-dropdown:hover>.submenu {
    display: block;
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
  }
}

@media only screen and (max-width: 992px) {
  .menu {
    width: 100%;
    height: auto;
    padding: 1rem 0;
  }

  .menu-item {
    display: block;
    margin: 0 auto;
  }

  .menu-link {
    justify-content: space-between;
    padding: 0.5rem 1.25rem;
  }

  .close-icon {
    font-size: 25px;
  }
}

.submenu {
  position: absolute;
  top: 2.35rem;
  left: -2rem;
  min-width: 13rem;
  height: auto;
  padding: 0 1rem 1rem;
  opacity: 0;
  visibility: hidden;
  transform: translateY(1rem);
  border-radius: 0 0 0.25rem 0.25rem;
  border-top: 2px solid #ae8c53;
  box-shadow: var(--shadow-medium);
  background-color: #252926;
  transition: all 0.3s ease-in-out;
}

.submenu-item {
  display: block;
  margin-top: 0.75rem;
}

.submenu-link {
  font-family: inherit;
  font-size: 1rem;
  font-weight: 500;
  line-height: inherit;
  cursor: pointer;
  color: #ae8c53;
  transition: all 0.35s ease;
}

.submenu-link:hover {
  outline: none;
  color: #ae8c53;
  text-decoration: none;
}

@media only screen and (max-width: 992px) {
  .submenu {
    position: relative;
    top: -0.5rem;
    left: 2.5rem;
    width: 100%;
    max-height: 0;
    padding: 0px;
    border: none;
    outline: none;
    opacity: 1;
    overflow: hidden;
    visibility: visible;
    transform: translateY(0px);
    box-shadow: none;
    background: transparent;
  }
}

.burger {
  position: relative;
  display: none;
  cursor: pointer;
  user-select: none;
  width: 1.6rem;
  height: 1.15rem;
  opacity: 0;
  visibility: hidden;
  background: transparent;
  z-index: 999;
}

.burger-line {
  position: absolute;
  display: block;
  right: 0;
  width: 100%;
  height: 2.1px;
  opacity: 1;
  border: none;
  outline: none;
  border-radius: 1rem;
  background: #407acf;
}

.burger-line:nth-child(1) {
  /* width: 68%; */
  top: 0px;
}

.burger-line:nth-child(2) {
  top: 0.5rem;
  width: 100%;
}

.burger-line:nth-child(3) {
  /* width: 9%; */
  top: 1rem;
}

@media only screen and (max-width: 992px) {
  .burger {
    display: block;
    opacity: 1;
    visibility: visible;
  }

  .navbar {
    align-items: flex-start;
    padding-top: 50px;
  }
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 9;
  opacity: 0;
  visibility: hidden;
  transition: all 0.35s ease-in-out;
  background-color: rgba(0, 0, 0, 0.85);
}

@media only screen and (max-width: 992px) {
  .overlay.active {
    display: block;
    opacity: 1;
    visibility: visible;
    height: 100vh;
    background: black;
  }

  .acc-item {
    margin-bottom: 15px;
  }
}

@media only screen and (max-width: 800px) {

  .logo {
    height: 35px;
  }
}