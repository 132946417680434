#feedback_form .card-container {
    background-color: #f4f8ff;
}

#feedback_form .feedback-form {
    max-height: 30%;
}

#feedback_form .logo {
    width: 25%;
    height: 65px;
    display: block;
    margin: 0 auto 1rem;
}

#feedback_form .form-title {
    text-decoration: underline;
}

#feedback_form .highlight {
    color: #bf5af2;
}

#feedback_form .category-title {
    margin-bottom: 0.5rem;
    text-transform: capitalize;
}

#feedback_form .rating-container {
    justify-content: center;
    margin-bottom: 1.5rem;
}

#feedback_form .star {
    cursor: pointer;
    font-size: 3rem;
    margin: 0 5px;
    color: #d3d3d3;
    transition: color 0.2s, transform 0.2s;
}

#feedback_form .star:hover {
    transform: scale(1.2);
}

#feedback_form .star.filled {
    color: #f6c700;
}

@media (max-width: 768px) {
    #feedback_form .feedback-form {
        padding: 2rem;
    }

    #feedback_form .star {
        font-size: 2.5rem;
    }
}


#feedback_form .radio-button {
    border: 1px solid black;
}