.scroll-to-top {
    position: fixed;
    bottom: 20px;
    right: 20px;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.4s ease, visibility 0.4s ease;
  }
  
  .scroll-to-top.show {
    opacity: 1;
    visibility: visible;
  }
  
  @keyframes waveAnimation {
    0%, 100% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-10px);
    }
  }
  
  .scroll-to-top button {
    background-color:#173e87; /* Button background color */
    color: #fff; /* Button text color */
    border: none;
    outline: none;
    cursor: pointer;
    padding: 10px 15px;
    border-radius: 50%;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    animation: waveAnimation 2s infinite;
    transition: background-color 0.3s ease;
  }
  
  .scroll-to-top button:hover {
    background-color: #0056b3; /* Button background color on hover */
  }
  
  .scroll-to-top button i {
    font-size: 20px;
  }
  