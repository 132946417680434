.amenitiesWrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;
  /* margin-top: 4px; */
}

.amenity-check-label {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 8px;
  border-radius: 6px;
  cursor: pointer;
  border: 2px solid;
}

.amenitiesWrapper .amenity-check-label.selected {
  background-color: #0b5dd74d;
  border: 2px solid #0b5ed7;
  color: #0b5ed7;
}

.amenitiesWrapper .amenity-check-label.normal {
  border: 2px solid gray;
  color: gray;
}

.amenitiesWrapper .amenity-check-label span {
  white-space: nowrap;
  display: flex;
  align-items: center;
  gap: 8px;
  font-weight: 600;
}

.amenitiesWrapper .amenity-check-label input {
  display: none;
}
