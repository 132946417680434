@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  min-width: 0;
}
:root {
  --primary-color: #5db64c !important;
  --primaryShade-bgColor: #5db64c45 !important;
  --primaryShade-Color: rgb(46, 126, 30) !important;
}
html,
body {
  overflow-x: hidden;
}
