.priceAmenitiesContainer {
    display:flex;
    grid-template-columns: repeat(auto-fit, minmax(0, 1fr));
    gap: 8px;
    row-gap: 22px !important;
    margin-bottom: 20px;
}

@media(min-width:515px) {
    .priceAmenitiesContainer{
    font-size:15px;
    flex-direction: column !important;
    align-items: flex-end;
}
    
}