.dop{
    color:black;
    text-decoration: none;
}
.dop:hover{
    color:black;
    text-decoration: none;
}
.head{
    font-size: 25px;
    font-weight: 700;
    text-align: center;
    margin-bottom: 5px;
}
.head-desc{
font-size: 19px;
font-weight: 400;
text-align: center;
}
.heighlightmy-top
{
    margin-top:0px;
    margin-bottom:30px;
    padding-top:20px;
    padding-bottom:50px;
    padding-left: 120px;
    padding-right: 120px;
    background-color:rgba(220, 220, 220, 0.3);


}

.stay1-txt2{
    font-size: 22px;
    color: black;
    font-weight: 700;
    margin-bottom: 5px;
    margin-top: 5px;

}
.stay-txt3{
    color: rgb(78, 77, 77);
    font-weight: 600;
    font-size: 15px;
}
.lightcard{
    /* display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column; */
    background-color: white;
    border-radius: 10px;
    margin-top: 25px;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
    /* height:250px; */
    cursor: pointer;
    padding:25px 25px 25px 25px;
}
.lightcard:hover{
margin-top: 20px;
transition: 0.7s;
}

.lightcard2
{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    gap:15px;
    margin-bottom: 15px;
}
.highlight-img{
    height:50px;
}
.iconn{
    margin-left: 6px;
}
.freebtn{
    float: right;
    
}
.relate{
    position: relative;
}
.bot-flx1{
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
.feature-icn {
    /* background-color: #208fe1; */
    border-radius: 50%;
    color: #fff;
    font-size: 16px;
    padding: 11px 9px 11px 11px;
}
@media(max-width:900px)
{
    .heighlightmy-top {
        margin-top: 0px;
        margin-bottom: 30px;
        padding-top: 20px;
        padding-bottom: 50px;
        padding-left: 40px;
        padding-right: 40px;
        background-color: rgba(220, 220, 220, 0.3);
    }
    .stay-txt {
        font-size: 50px;
        font-weight: 800;
        text-align: center;
    }
    .stay-desc {
        padding-left: 30px;
    padding-right: 30px;
    }
}
@media(max-width:510px)
{
    .heighlightmy-top {
        margin-top: 0px;
        margin-bottom: 30px;
        padding-top: 20px;
        padding-bottom: 40px;
        padding-left: 10px;
        padding-right: 10px;
        background-color: rgba(220, 220, 220, 0.3);
    }
    .stay-txt {
        font-size: 32px;
        font-weight: 800;
        text-align: center;
    }
    .top {
        margin-top: 16%;
    }
    .stay-desc {
        padding-left: 10px;
    padding-right: 10px;
    }
    .hotel-feature-top02 {

flex-direction:column-reverse ;
    }
}