@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

.dashboardfont {
  font-family: "Poppins", sans-serif;
}

.col_parent_class {
  padding: 0;
  margin: 0;
}

.second_child_width {
  width: 82.5%;
}
.first_child_width {
  width: 17.5%;
}

.side-pad {
  padding-left: 0px;
}
.featured-flx {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 60px;
  margin-bottom: 0px;
  margin-top: 20px;
}
.main_heading1 {
  font-size: 30px;
  font-weight: 400;
  text-align: left;
  margin-top: 0px;
  margin-bottom: 10px;
  /*margin-left: 90px;*/
}
.horizontalline {
  height: 4px;
  width: 130px;
  background-color: #007bff;
  border: none;
  margin-left: 0px;
  margin-top: 0px;
  margin-bottom: 20px;
  opacity: 1;
}
.location-top {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
}
.circle-btn-add {
  width: 40px;
  height: 40px;
  background-color: #007bff;
  border: none;
  border-radius: 50%;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 14px;
  font-size: 17px;
  padding-right: 1px;
}

.location-top2 {
  display: flex;
  justify-content: flex-end !important;
  align-items: center;
  gap: 10px;
}
.dropbtn {
  color: black;
  padding: 7px;
  font-size: 22px;
  background: transparent;
  border: none;
  cursor: pointer;
}

.dropdown {
  position: relative;
  /* display: inline-block;
  width: 100%; */
}

.drop-width {
  position: relative;
  display: inline-block;
  width: auto;
}
.dropdown-content {
  display: none;
  position: absolute;
  right: 0;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-content a:hover {
  background-color: #f1f1f1;
}
.dropdown:hover .dropdown-content {
  display: block;
}
/*.dropdown:hover .dropbtn {background-color: #3e8e41;}*/

.location-top2 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  margin-top: 0px;
}
.featured-flx1 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 5px;
}

.space-txt {
  margin-bottom: 0px;
  font-size: 20px;
  font-weight: 500;
  margin-top: 5px;
}
.location-card {
  /* box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px; */
  box-shadow: rgba(17, 17, 26, 0.1) 0px 0px 16px;
  padding: 20px 15px 20px 15px;
  margin-bottom: 40px;
  border-radius: 5px;
  /* background-color: red; */
}
.view-btn {
  padding-left: 20px;
  padding-right: 20px;
  height: 40px;
  background-color: #007bff;
  border: none;
  border-radius: 5px;
  margin-right: 0px;
  color: #fff;
  float: right;
}
.inner-padding {
  padding-left: 40px;
  padding-right: 40px;
  margin-top: 100px;
}
/* .space-option > .inner-padding1 {
  padding-left: 40px;
  padding-right: 40px;
  margin-top: 30px;
} */

.circle-btn-A {
  width: 40px;
  height: 40px;
  background-color: #007bff;
  border: none;
  border-radius: 50%;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 14px;
  margin-top: 9px;
}
.view-btn-2 {
  padding-left: 12px;
  padding-right: 12px;
  height: 40px;
  border: 1px solid #007bff;
  color: #007bff;

  border-radius: 5px;
  margin-right: 0px;
  /* color: #fff; */
  float: right;
  font-size: 14px;
  margin-right: 10px;
}
.location-txt {
  margin-top: 7px;
  font-size: 15px;
}
.space-1-txt {
  margin-top: 7px;
  font-size: 15px;
}

/* dashboardcss */
.dashboard-card {
  padding: 15px;
  background-color: #ffffff;
  box-shadow: rgba(17, 17, 26, 0.1) 0px 0px 16px;
  color: black;
  border-radius: 8px;
  height: 200px;
  margin-bottom: 20px;
  /* background: red; */
}
.dashboard-card:hover {
  background-color: #007bff;
  color: white;
  transition: 0.8s;
}
.des-icn {
  font-size: 25px;
}
.dashboard-flx {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 30px;
}
.dash-data {
  font-size: 28px;
  margin-bottom: 0px;
}
.space-data {
  font-size: 20px;
  margin-bottom: 0px;
}
.space-data1 {
  font-size: 15px;
  margin-bottom: 0px;
  color: rgb(148, 148, 148);
}
.botflx {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}
.pencil-icn {
  padding: 8px;
  border: 1px solid #007bff;
  color: #007bff;
  border-radius: 5px;
  font-size: 14px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 40px;
  font-size: 15px;
  padding-left: 9px;
}
.editicn {
  display: flex;
  justify-content: center;
  align-items: center;
}
.trash-icn {
  padding: 8px;
  border: 1px solid #007bff;
  color: #007bff;
  border-radius: 5px;
  margin-left: 10px;
  font-size: 14px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 40px;
  font-size: 15px;
  padding-left: 9px;
}
.view-btn-2:hover,
.trash-icn:hover,
.pencil-icn:hover {
  background-color: #007bff;
  color: white;
  transition: 0.7s;
}
.dash-data1 {
  font-size: 17px;
  margin-bottom: 0px;
}
.dash-line {
  margin: 0px;
  width: 60%;
  margin-top: 8px;
  margin-bottom: 8px;
}
.dashboard_secondcard {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  flex-direction: column;
  margin-top: 18px;
}
.resource-flx {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  padding: 15px;
}
.resource-card {
  box-shadow: rgba(17, 17, 26, 0.1) 0px 0px 16px;
  margin-bottom: 20px;
  border-radius: 8px;
}
.resource-sheet {
  background-color: rgb(242, 242, 242);
  padding: 20px;
}
.cabin-sheet {
  padding-left: 15px;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  margin-bottom: 15px;
  gap: 20px;
}
.cabin-number {
  height: 50px;
  width: 50px;
  border: 2px solid #007bff;
  color: #007bff;
  background-color: white;
  border-radius: 50%;
  font-size: 13px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 2px;
}
.cabins-card {
  /* box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px; */
  box-shadow: rgba(17, 17, 26, 0.1) 0px 0px 16px;
  padding: 20px 0px 20px 0px;
  margin-bottom: 40px;
  border-radius: 5px;
  /* background-color: red; */
  position: relative;
}
.total-number {
  height: 90px;
  width: 90px;
  border: 2px solid #007bff;
  color: #007bff;
  background-color: white;
  border-radius: 50%;
  font-size: 27px;
}
.resource-sheet {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}
.botflx-cabin {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}
.cabin-count {
  height: 35px;
  width: auto;
  background: #007bff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0px 4px 4px 0px;
  padding-left: 17px;
  padding-right: 17px;
  font-size: 18px;
  color: white;
}
.cabin-space-txt {
  margin-bottom: 0px;
  font-size: 21px;
  font-weight: 500;
}
.cabin-totalseat {
  margin-bottom: 0px;
  font-size: 19px;
  font-weight: 500;
}
.totalseat {
  margin-bottom: 0px;
  font-size: 22px;
  font-weight: 600;
}
.more-btm {
  display: flex;
  justify-content: flex-end;
}
.dropdownborder {
  border: none;
}
.show {
  border: none;
  outline: none;
}
.show.active {
  border: none;
  outline: none;
}
.dropdown-menu {
  background-color: #f0f0f0;
  color: white;
}
.card-top {
  margin-top: 5px;
}
.resource-sider {
  padding: 10px 0px 10px 10px;
}
.droptop {
  margin-top: 10px;
}
.search-container {
  position: relative;
  display: inline-block;
}

.search-bar {
  width: 0;
  overflow: hidden;
  transition: width 0.3s ease, border-bottom 0.3s ease; /* Added border-bottom transition */
  padding: 10px;
  border: none;
  background: transparent;
  position: absolute;
  right: 0;
  top: 0;
  border-bottom: 2px solid transparent; /* Initially transparent border bottom */
  outline: none; /* Remove outline */
  padding-right: 60px;
}

.search-bar.expanded {
  width: 300px; /* Adjust this width as needed */
  border-bottom-color: #d1d1d1; /* Change border bottom color when expanded */
}

.search-button {
  position: relative;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: none;
  background-color: #ccc;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
.button-container {
  display: flex;
  justify-content: flex-end;
  gap: 8px;
}
.page-flx {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
}
.page-txt1 {
  margin-bottom: 0px;
  font-size: 16px;
}
.button {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background-color: white;
  color: black;
  border: 1px solid #cdcdcd;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.button.active {
  background-color: #007bff;
  color: white;
}
.page-flx {
  margin-bottom: 40px;
}
/* th,
td {
  padding: 12px !important;
  text-align: left;
} */
.edt-flx {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.edt-icn {
  padding: 8px;
  border: 1px solid #007bff;
  color: #007bff;
  border-radius: 5px;
  font-size: 12px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 25px;
  width: 25px;
  padding-left: 9px;
}
.view-icn {
  padding: 8px;
  border: 1px solid #007bff;
  color: #007bff;
  border-radius: 5px;

  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 25px;
  width: 25px;
  font-size: 12px;
  padding-left: 9px;
  margin-left: 10px;
}
.dlt-icn {
  padding: 4px;
  border: 1px solid #007bff;
  color: #007bff;
  border-radius: 5px;
  margin-left: 10px;
  font-size: 12px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 25px;
  width: 25px;

  padding-left: 6px;
}
.tables-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 30px;
  margin-bottom: 30px;
}
.location-select {
  height: 40px;
  padding-left: 8px;
  padding-right: 30px;
  border: none;
  border-radius: 5px;
  outline: none;
}
.select-upr {
  border-radius: 5px;
  outline: none;
  padding-left: 0px;
  padding-right: 3px;
  box-shadow: rgba(67, 71, 85, 0.27) 0px 0px 0.25em, rgba(90, 125, 188, 0.05) 0px 0.25em 1em;
}
.side-shadow {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  padding: 20px;
  margin-bottom: 30px;
}
.post-card {
  position: relative;
}

.space-card {
  padding: 15px;
  background-color: #ffffff;
  box-shadow: rgba(17, 17, 26, 0.1) 0px 0px 16px;
  color: black;
  border-radius: 8px;
  height: 250px;
  margin-bottom: 20px;
  /* background: red; */
}

.space1-icn {
  height: 40px;
  width: 40px;
  background-color: #007bff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  color: white;
}
.space_secondcard {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 5px;
  flex-direction: column;
  margin-top: 18px;
}
.space-btn-2 {
  padding-left: 12px;
  padding-right: 12px;
  height: 40px;
  border: 1px solid #007bff;
  color: #007bff;
  margin-top: 16px;
  border-radius: 5px;
  margin-right: 0px;
  /* color: #fff; */
  float: right;
  font-size: 14px;
  margin-right: 10px;
}
.space-icn {
  font-size: 18px;
}
.space1-txt1 {
  margin-bottom: 0px;
  font-size: 20px;
  font-weight: 500;
  margin-top: 10px;

  position: relative;
  display: inline-block;
}

/* Tooltip text */
.space1-txt1 .tooltiptext {
  visibility: hidden;
  width: fit-content;
  background-color: rgba(67, 66, 66, 0.933);
  color: #fff;
  text-align: center;
  padding: 3px 5px;
  border-radius: 6px;
  text-wrap: nowrap;
  font-size: 16px;

  position: absolute;
  z-index: 1;
  top: 100%;
  left: 50%;
  margin-left: -50%;
  opacity: 0;
  transition: opacity 0.4s;
}

.space1-txt1:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}

.space1-txt1 .tooltiptext::after {
  content: " ";
  position: absolute;
  bottom: 100%; /* At the top of the tooltip */
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent rgba(67, 66, 66, 0.933) transparent;
}

.space-circle-btn-A {
  width: 40px;
  height: 40px;
  background-color: #007bff;
  border: none;
  border-radius: 50%;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 14px;
  margin-top: 0px;
  cursor: default !important;
}

.space1-card {
  /* box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px; */
  box-shadow: rgba(17, 17, 26, 0.1) 0px 0px 16px;
  padding: 20px 15px 20px 15px;
  margin-bottom: 40px;
  border-radius: 5px;
  height: 250px;
}
.keybord-icn {
  font-size: 22px;
}
.cabin-sideline {
  height: 10px;
  width: 20px;
  background-color: #007bff;
  position: absolute;
  top: 0px;
  right: 0px;
  /* border-radius: 10px 0px 0px 10px; */
}
.cabin-sideline1 {
  height: 40px;
  width: 40px;
  background-color: #007bff;
  position: absolute;
  bottom: 0px;
  left: 0px;
  border-radius: 0px 90px 0px 0px;
  /* border-radius: 10px 0px 0px 10px; */
}
.cabin-editicn {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: 15px;
}
.cafitarea-card {
  height: 120px;
  box-shadow: rgba(17, 17, 26, 0.1) 0px 0px 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  border-left: 8px solid #007bff;
  position: relative;
}

.cafit-txt {
  margin-bottom: 0px;
  font-size: 22px;
  font-weight: 500;
}
.botflx-cafit {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
}
.side-space-data {
  padding-left: 90px;
  padding-right: 90px;
}
.space-nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 25px 15px 25px;

  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}
.space-nav2 {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.spacenav-circle-btnname {
  height: 40px;
  padding-left: 16px;
  padding-right: 16px;
  background-color: #007bff;
  border: none;
  border-radius: 5px;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 14px;
  font-size: 14px;
  margin-right: 0px;
  border-radius: 5px;
}
.hotel_card > .cabinspace1-card {
  /* box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px; */
  box-shadow: rgba(17, 17, 26, 0.1) 3px 3px 16px;
  padding: 20px 18px 20px 18px;
  margin-bottom: 25px;
  border-radius: 0px 10px 10px 10px;
  /* height: 250px;   change by MS */
}
.space-side-border {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 7px;
  padding-left: 15px;
  border-right: 2px solid rgb(206, 206, 206);
  margin-left: 15px;
  padding-right: 20px;
  margin-right: 20px;
}
.user-icn-nav {
  width: 40px;
  height: 40px;
  background-color: #007bff;
  border: none;
  border-radius: 50%;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 14px;
  margin-top: 0px;
}
.space-user-n1 {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 14px;
}
@media (max-width: 1100px) {
  .second_child_width {
    width: 100%;
  }
  .first_child_width {
    width: 100%;
  }
}
@media (max-width: 990px) {
  .second_child_width {
    width: 100%;
  }
  .first_child_width {
    width: 100%;
  }
  .dash-data {
    font-size: 22px;
    margin-bottom: 0px;
  }
  .dash-data1 {
    font-size: 15px;
    margin-bottom: 0px;
  }
  .inner-padding {
    padding-left: 20px;
    padding-right: 20px;
    margin-top: 40px;
  }
  .search-bar.expanded {
    width: 190px; /* Adjust this width as needed */
    border-bottom-color: #d1d1d1; /* Change border bottom color when expanded */
    background-color: white;
  }
  .side-space-data {
    padding-left: 20px;
    padding-right: 20px;
  }
}

@media(max-width: 431px){
  .user-icn-nav {
    width: 46px;
    height: 40px;
    border-radius:50%;
}
}
