.stay-txt
{
    font-size: 70px;
    font-weight: 800;
    text-align: center;
}
.top-header
{
    margin-top:9%;
}
.backtxt
{
    background-color: black;
    color:white;
    border-radius: 5px;
    padding-left: 15px;
    padding-right: 15px;
}
.stay-desc
{
    font-size: 18px;
    padding-left: 90px;
    padding-right: 90px;
    text-align: center;
    margin-bottom: 2%;
    margin-top: 2%;
    color: rgb(78, 77, 77);
    font-weight: 600;
}
.stay-book
{
    margin-top: 20px;
    height: 50px;
    /* padding:15px; */
    padding-left: 40px;
    padding-right: 40px;
    border:1px solid rgb(174, 174, 174);
    border-radius: 8px;
}
@media(max-width:800px)
{
    .top-header
{
    margin-top:26%;
    margin-bottom: 6%;
}
}